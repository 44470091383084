import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { EditStoreProvider, EditStoreContext } from '../../src/EditStore';
import { Button } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "editstoreprovider"
    }}>{`EditStoreProvider`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { EditStoreProvider } from '@mfrm/mfcl/EditStore'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`EditStoreProvider provied a context to persist EditStoreModal between application pages.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={EditStoreProvider} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<EditStoreProvider getStoresList={() => []} saveMyStoreInfo={() => null}>\n  <EditStoreContext.Consumer>\n    {({ toggleEditStoreModal }) => (\n      <Button\n        btnType=\"primary\"\n        onClick={() => toggleEditStoreModal()}\n        size=\"lg\"\n      >\n        Open Edit Store Modal\n      </Button>\n    )}\n  </EditStoreContext.Consumer>\n</EditStoreProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      EditStoreProvider,
      EditStoreContext,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <EditStoreProvider getStoresList={() => []} saveMyStoreInfo={() => null} mdxType="EditStoreProvider">
    <EditStoreContext.Consumer>
      {({
            toggleEditStoreModal
          }) => <Button btnType="primary" onClick={() => toggleEditStoreModal()} size="lg" mdxType="Button">
          Open Edit Store Modal
        </Button>}
    </EditStoreContext.Consumer>
  </EditStoreProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      